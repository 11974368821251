<template>
    <div class="sld_account_manage">
        <MemberTitle memberTitle="权限组" style="padding-left: 20px"></MemberTitle>
        <div class="sld_account_manage_one">
            <div class="section3" @click="operate('add')">新增权限组</div>
            <div class="mod3">
                <div class="section4 flex_row_start_center">
                    <div class="info3 name">权限组名称</div>
                    <div class="info3 desc">权限组描述</div>
                    <div class="info3 create">创建时间</div>
                    <div class="info3 update">更新时间</div>
                    <div class="info3 operate">操作</div>
                </div>
                <div v-for="(item, index) in list.data" :key="index" class="section5 flex_row_start_center">
                    <div class="info4 name">{{ item.groupName }}</div>
                    <div class="info4 desc" :title="item.description">{{ item.describe }}</div>
                    <div class="info4 create">{{ item.createTime }}</div>
                    <div class="info3 update">{{ item.updateTime || "--" }}</div>
                    <div class="info4 operate">
                        <span class="block1" @click="operate('edit', index)">编辑</span>
                        <span class="block1" @click="operate('auth', index)">授权</span>
                        <el-popconfirm title="删除后不可恢复，是否确定删除" @confirm="operate('del', index)">
                            <template #reference>
                                <span class="block1">删除</span>
                            </template>
                        </el-popconfirm>
                    </div>
                </div>
                <SldCommonEmpty v-if="!list.data.length" totalWidth="970" totalHeight="350" />
            </div>

            <!-- 分页 start -->
            <div class="flex_row_end_center sld_pagination">
                <el-pagination
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next, jumper"
                    :page-size="pageData.pageSize"
                    :currentPage="pageData.current"
                    :total="pageData.total"
                    :hide-on-single-page="true"
                >
                </el-pagination>
            </div>
            <!-- 分页 end -->

            <!-- 权限组弹窗 start -->
            <el-dialog v-model="editVisible" :title="editTitle" @close="close_operate" width="440px" top="20vh">
                <div class="dialog flex_column_center_center">
                    <div class="dialog_title flex_row_between_center">
                        <span>{{ editTitle }}</span>
                        <i class="iconfont icon-ziyuan51" @click="close_operate"></i>
                    </div>
                    <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">权限组名称：</span></div>
                        <div>
                            <el-input v-model="popInfo.roleName" placeholder="请输入权限组名称" maxlength="10" />
                            <p class="tips_p">最多输入10个字</p>
                        </div>
                    </div>
                    <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit">权限组描述：</div>
                        <div>
                            <el-input
                                v-model="popInfo.description"
                                type="textarea"
                                placeholder="请输入权限组描述"
                                resize="none"
                                maxlength="50"
                                show-word-limit
                            />
                            <p class="tips_p">最多输入50个字</p>
                        </div>
                    </div>
                    <div class="dialog_btn flex_row_end_center">
                        <div class="dialog_close" @click="close_operate">取消</div>
                        <div class="dialog_submit" @click="submit_operate">确定</div>
                    </div>
                </div>
            </el-dialog>
            <!-- 权限组弹窗 end -->

            <!-- 授权弹窗 start -->
            <el-dialog v-model="authVisible" title="授权" @close="close_operate" width="700px">
                <div class="dialog flex_column_center_center">
                    <div class="dialog_title flex_row_between_center">
                        <span>授权</span>
                        <i class="iconfont icon-ziyuan51" @click="close_operate"></i>
                    </div>

                    <div class="dialog_checkbox">
                        <p class="flex_row_start_center">
                            <el-checkbox v-model="operatecheck" :indeterminate="isIndeterminate" @change="checkAll">
                                全选
                            </el-checkbox>
                            <!-- <span></span> -->
                        </p>
                        <el-tree
                            ref="treeRef"
                            :data="mandateList"
                            :height="auto"
                            :props="mandateProps"
                            node-key="permissionId"
                            show-checkbox
                            @check-change="checkFlag"
                            default-expand-all="true"
                            :default-checked-keys="defaultCheckedKeys"
                        />
                        <!-- 可切换格式 -->
                        <!-- <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                            <el-tab-pane label="菜单权限" name="first">
                                <el-tree
                                    ref="treeRef"
                                    :data="mandateList"
                                    :height="auto"
                                    :props="mandateProps"
                                    node-key="permissionId"
                                    show-checkbox
                                    default-expand-all="true"
                                    :default-checked-keys="defaultCheckedKeys"
                                />
                            </el-tab-pane>
                            <el-tab-pane label="按钮权限" name="second"> </el-tab-pane>
                        </el-tabs> -->
                        <!-- <div class="dialog_checkbox_group">
                            <el-checkbox
                                v-model="item.isCheck"
                                :label="item.permsName"
                                v-for="(item, index) in authList.data"
                                :key="index"
                                @change="changeSelect(item.permsId)"
                            />
                        </div> -->
                        <!-- <template v-for="(item,index) in authList.data" :key="index">
                        <div class="dialog_checkbox_group">
                            <el-checkbox v-model="item.checked" :label="item.content"
                                :disabled="item.state?false:true" @change="changeSelect('all', index)"/>
                        </div>
                        <div class="dialog_checkbox_item flex_row_start_center">
                            <template v-for="(items,indexs) in item.children" :key="indexs">
                                <el-checkbox v-model="items.checked" :label="items.content"
                                    :disabled="items.state?false:true" @change="changeSelect('single', index)"/>
                            </template>
                        </div>
                    </template> -->
                    </div>

                    <div class="dialog_btn dialog_btn_auth flex_row_end_center">
                        <!-- <p class="dialog_submit" @click="checkAll">全选</p> -->
                        <!-- <div class="flex_row_end_center"> -->
                        <div class="dialog_close" @click="close_operate">取消</div>
                        <div class="dialog_submit" @click="submit_operate">确定</div>
                        <!-- </div> -->
                    </div>
                </div>
            </el-dialog>
            <!-- 授权弹窗 end -->
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import SldCommonEmpty from "@/components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
export default {
    name: "AccountList",
    components: { SldCommonEmpty, MemberTitle },
    setup() {
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const store = useStore();
        const pageData = reactive({
            current: 1,
            pageSize: 10,
            total: 0
        });
        const isIndeterminate = ref(true);
        const operatecheck = ref(false); //
        const count = ref(0);
        const list = reactive({ data: [] });
        const roleName = ref("");
        const editVisible = ref(false);
        const editType = ref("");
        const editTitle = ref("");
        const authVisible = ref(false);
        const authList = reactive({ data: [] });
        const popInfo = reactive({
            groupId: "", //权限组id
            roleName: "", //权限组名称（最多10个字）
            description: "" //权限组描述（最多50字）
        });
        const activeName = ref("first"); //权限弹窗菜单和按钮的tab切换
        const mandateProps = {
            label: "permissionName",
            children: "children"
        };

        const treeRef = ref(null); //权限树
        const defaultCheckedKeys = ref([]);

        //授权数据渲染
        const mandateList = ref([]);

        const initList = () => {
            pageData.current = 1;
            pageData.total = 0;
            getList();
        };

        const reset = () => {
            roleName.value = "";
            pageData.current = 1;
            pageData.total = 0;
            getList();
        };

        const getList = () => {
            console.log(store.state.memberInfo, 123);
            let param = {
                corpCode: store.state.memberInfo.corpCode
            };
            proxy.$get("api/shopp/member/group/list", param).then((res) => {
                for (let i = 0; i < res.data.length; i++) {
                    if (!res.data[i].roleIds) {
                        res.data[i].isLookActiveBill = false;
                        res.data[i].isLookSettleAccounts = false;
                        res.data[i].isLookHistoryBill = false;
                    } else {
                        let arr = res.data[i].roleIds.split(",");
                        res.data[i].isLookActiveBill = JSON.parse(arr[0]);
                        res.data[i].isLookSettleAccounts = JSON.parse(arr[1]);
                        res.data[i].isLookHistoryBill = JSON.parse(arr[2]);
                    }
                }
                list.data = res.data;
                console.log(list.data);
                // pageData.total = res.data.pagination.total;
            });
        };

        const handleCurrentChange = (current) => {
            pageData.current = current;
            getList();
        };

        const operate = (type, index) => {
            editType.value = type;
            if (type == "add") {
                editVisible.value = true;
                editTitle.value = "新增权限组";
            } else if (type == "edit") {
                editVisible.value = true;
                editTitle.value = "编辑权限组";
                popInfo.groupId = list.data[index].groupId;
                popInfo.roleName = list.data[index].groupName;
                popInfo.description = list.data[index].describe;
            } else if (type == "auth") {
                popInfo.groupId = list.data[index].groupId;
                authList.data = [];
                getAuthList(index);

                // authList.isCanBuy = list.data[index].isCanBuy==null ? false :
                //     list.data[index].isCanBuy==1 ? true : false;
                // if(list.data[index].resourcesList.length){
                //     authList.data.forEach(item=>{
                //         let num = 0;
                //         item.children.forEach(items=>{
                //             if(items.state==1 && list.data[index].resourcesList.indexOf(items.resourceId)!=-1){
                //                 items.checked = true;
                //                 num++;
                //             }
                //         })
                //         if(num == item.children.length){
                //             item.checked = true;
                //         }
                //     })
                // }else{
                //     authList.data.forEach(item=>{
                //         item.checked = false;
                //         item.children.forEach(items=>{
                //             items.checked = false;
                //         })
                //     })
                // }
            } else if (type == "del") {
                let param = {};
                param.groupId = list.data[index].groupId;
                proxy.$post("api/shopp/member/delete/group", param).then((res) => {
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        getList();
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            }
        };

        const close_operate = () => {
            if (editType.value == "add") {
                editVisible.value = false;
                editTitle.value = "新增权限组";
            } else if (editType.value == "edit") {
                editVisible.value = false;
                editTitle.value = "编辑权限组";
            } else if (editType.value == "auth") {
                authVisible.value = false;
            }
            popInfo.groupId = "";
            popInfo.roleName = "";
            popInfo.description = "";
        };

        const submit_operate = () => {
            if (editType.value == "add" || editType.value == "edit") {
                if (!popInfo.roleName) {
                    ElMessage.info("请输入权限组名称");
                    return;
                }
                // else if(!popInfo.description){
                //     ElMessage.info('请输入权限组描述');
                //     return;
                // }
                let param = {
                    groupName: popInfo.roleName,
                    describe: popInfo.description
                };

                let url = "";
                if (editType.value == "add") {
                    url = "api/shopp/member/save/group";
                    proxy.$post(url, param, "json").then((res) => {
                        if (res.code == 200) {
                            ElMessage.success(res.msg);
                            editVisible.value = false;
                            editTitle.value = "";
                            popInfo.groupId = "";
                            popInfo.roleName = "";
                            popInfo.description = "";
                            reset();
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
                    return;
                } else {
                    param.groupId = popInfo.groupId;
                    url = "api/shopp/member/edit/group";
                    proxy.$post(url, param, "json").then((res) => {
                        if (res.code == 200) {
                            ElMessage.success(res.msg);
                            editVisible.value = false;
                            editTitle.value = "";
                            popInfo.groupId = "";
                            popInfo.roleName = "";
                            popInfo.description = "";
                            reset();
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
                    return;
                }
            } else if (editType.value == "auth") {
                //获取已选中树id
                let checkIds = treeRef.value.getCheckedKeys();
                let halfChcked = treeRef.value.getHalfCheckedKeys();
                let param = {
                    permsIds: [...checkIds, ...halfChcked],
                    groupId: popInfo.groupId
                };
                proxy.$post("api/shopp/member/save/group/perms", param, "json").then((res) => {
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        authVisible.value = false;
                        popInfo.groupId = null;
                        getList();
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
                // let resourceIds = [];
                // authList.data.forEach(item=>{
                //     if(item.checked){
                //         resourceIds.push(item.resourceId);
                //     }
                //     item.children.forEach(items=>{
                //         if(items.checked){
                //             resourceIds.push(items.resourceId);
                //         }
                //     })
                // })
                // let data = {
                //     resourceIds: resourceIds.join(), //3级资源id串，如 12,3,14
                //     roleId: popInfo.roleId, //角色id
                //     isLookOrder: authList.isLookOrder?1:0,
                //     isLookWallet: authList.isLookWallet?1:0,
                // }
                // proxy.$post('v3/member/front/memberRole/saveRoleResource', data).then(res=>{
                //     if(res.state == 200){
                //         ElMessage.success(res.msg);
                //         authVisible.value = false;
                //         popInfo.roleId = false;
                //         getList();
                //     }else{
                //         ElMessage.error(res.msg);
                //     }
                // })
            }
        };

        //获取权限资源列表
        const getAuthList = (i) => {
            defaultCheckedKeys.value = [];
            let param = {};
            param.groupId = list.data[i].groupId;
            count.value = 0;
            proxy.$get("api/shopp/member/group/perms", param).then((res) => {
                if (res.code == 200) {
                    mandateList.value = res.data;
                    mandateList.value.map((el) => {
                        if (el.selectedArrays?.length > 0) {
                            isIndeterminate.value = true;
                            defaultCheckedKeys.value = [...defaultCheckedKeys.value, ...el.selectedArrays];
                        }
                        count.value = el.children.length > 0 ? el.children.length + count.value : 0;
                    });
                    //  全不选
                    if (defaultCheckedKeys.value.length == 0) {
                        isIndeterminate.value = false;
                        operatecheck.value = false;
                    }
                    //全选
                    if (count.value == defaultCheckedKeys.value.length) {
                        isIndeterminate.value = false;
                        operatecheck.value = true;
                    }
                    authVisible.value = true;
                } else {
                    ElMessage.error(res.msg);
                }
            });
        };
        const getAllNodes = (node) => {
            const allNodes = [];
            const doTraversal = function (node) {
                allNodes.push(node.data); // 或者其他需要的数据
                if (node.childNodes && node.childNodes.length) {
                    node.childNodes.forEach((child) => {
                        doTraversal(child);
                    });
                }
            };
            doTraversal(node);
            return allNodes;
        };
        const checkFlag = () => {
            let ee = Object.keys(treeRef.value.store.nodesMap).length;
            let node = treeRef.value.getCheckedKeys();
            if (node.length == ee) {
                isIndeterminate.value = false;
                operatecheck.value = true;
            } else if (node.length > 0 && node.length != ee) {
                isIndeterminate.value = true;
            } else if (node.length == 0) {
                isIndeterminate.value = false;
                operatecheck.value = false;
            }
        };
        const checkAll = (flag) => {
            //授权全选
            // 获取所有节点的 key 数组
            const allKeys = mandateList.value.map((item) => item.permissionId);
            if (flag) {
                // 使用 setCheckedKeys 方法设置选中的节点
                treeRef.value.setCheckedKeys(allKeys);
            } else {
                treeRef.value.setCheckedKeys([]);
            }
            isIndeterminate.value = false;
            console.log(222);
        };
        const changeSelect = (id) => {
            console.log(id);
        };

        onMounted(() => {
            getList();

            // getAuthList();
        });

        return {
            L,
            pageData,
            list,
            roleName,
            editVisible,
            editType,
            editTitle,
            authVisible,
            authList,
            popInfo,
            initList,
            reset,
            getList,
            handleCurrentChange,
            operate,
            close_operate,
            submit_operate,
            getAuthList,
            changeSelect,
            mandateList,
            activeName,
            mandateProps,
            defaultCheckedKeys,
            treeRef,
            checkAll,
            isIndeterminate,
            operatecheck,
            checkFlag
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/accountManage.scss";
</style>
<style lang="scss">
.sld_account_manage {
    .el-checkbox__input.is-checked + .el-checkbox__label,
    .el-checkbox__label {
        color: #606266;
        font-weight: 400;
    }
    .mod2 {
        .el-input {
            width: 280px;
            height: 30px;
        }
        .el-input__inner {
            width: 280px;
            height: 30px;
        }
        .el-input__suffix-inner {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 0;
    }

    .dialog {
        .dialog_title {
            width: 100%;
            height: 44px;
            background: $colorMain;
            padding: 12px 14px;
            color: #ffffff;
            span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
            }
            i {
                font-size: 14px;
                cursor: pointer;
            }
        }
        .dialog_info {
            width: 400px;
            margin-top: 20px;
            .tips_p {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #b5b5b5;
                margin-top: 5px;
            }
            .dialog_info_tit {
                width: 100px;
                line-height: 36px;
                flex-shrink: 0;
                color: #121212;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: right;
                white-space: nowrap;
                margin-right: 6px;
                .xing {
                    position: relative;
                    &:before {
                        content: "*";
                        position: absolute;
                        left: -7px;
                        color: #e2231a;
                    }
                }
            }
        }
        .el-input__inner {
            width: 290px;
            font-weight: 400;
            font-size: 14px;
            font-family: Microsoft YaHei;
        }
        .el-textarea__inner {
            width: 290px;
            min-height: 138px !important;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
        }
        .dialog_checkbox {
            width: 700px;
            padding: 20px;
            height: 500px;
            overflow: auto;
            // .dialog_checkbox_group {}
            .dialog_checkbox_item {
                margin-left: 24px;
                flex-wrap: wrap;
            }
            // .el-checkbox {
            //     margin-bottom: 16px;
            // }
        }
        .dialog_btn {
            width: 440px;
            border-top: 1px solid #f2f2f2;
            padding: 15px 20px;
            &.dialog_btn_auth {
                width: 700px;
            }
            .dialog_close,
            .dialog_submit {
                width: 78px;
                height: 36px;
                line-height: 36px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: center;
                border-radius: 6px;
                margin-left: 12px;
                cursor: pointer;
            }
            .dialog_close {
                color: #666262;
                background: #eeeeee;
            }
            .dialog_submit {
                color: #ffffff;
                background: $colorMain;
            }
        }
    }
}
</style>
